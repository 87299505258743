// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

function title(t) {
  return t.title;
}

function slug(t) {
  return t.slug;
}

function decode(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          slug: Json_decode.field("slug", Json_decode.string, json)
        };
}

export {
  title ,
  slug ,
  decode ,
  
}
/* No side effect */
