// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Header$PupilfirstCom from "../layouts/header/Header.bs.js";
import * as DomUtils$PupilfirstCom from "../shared/utils/DomUtils.bs.js";
import * as Header__Link$PupilfirstCom from "../layouts/header/types/Header__Link.bs.js";

function decodeProps(json) {
  return {
          links: Json_decode.field("links", (function (param) {
                  return Json_decode.array(Header__Link$PupilfirstCom.decode, param);
                }), json),
          isLoggedIn: Json_decode.field("isLoggedIn", Json_decode.bool, json),
          allowSignUp: Json_decode.field("allowSignUp", Json_decode.bool, json)
        };
}

var props = decodeProps(DomUtils$PupilfirstCom.parseJSONTag("pupilfirst-header-data", undefined));

ReactDOMRe.renderToElementWithId(React.createElement(Header$PupilfirstCom.make, {
          links: props.links,
          isLoggedIn: props.isLoggedIn,
          allowSignUp: props.allowSignUp
        }), "pupilfirst-header");

export {
  decodeProps ,
  props ,
  
}
/* props Not a pure module */
