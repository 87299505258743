// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Caml_exceptions from "bs-platform/lib/es6/caml_exceptions.js";

var DataElementMissing = Caml_exceptions.create("DomUtils-PupilfirstCom.DataElementMissing");

var RootElementMissing = Caml_exceptions.create("DomUtils-PupilfirstCom.RootElementMissing");

var RootAttributeMissing = Caml_exceptions.create("DomUtils-PupilfirstCom.RootAttributeMissing");

function parseJSONTag(idOpt, param) {
  var id = idOpt !== undefined ? idOpt : "react-root-data";
  var rootElement = document.getElementById(id);
  var tmp;
  if (rootElement == null) {
    throw [
          DataElementMissing,
          id
        ];
  }
  tmp = rootElement.innerHTML;
  return Json.parseOrRaise(tmp);
}

function redirect(path) {
  window.location = path;
  
}

export {
  DataElementMissing ,
  RootElementMissing ,
  RootAttributeMissing ,
  parseJSONTag ,
  redirect ,
  
}
/* No side effect */
