// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function renderIf(element, condition) {
  if (condition) {
    return element;
  } else {
    return null;
  }
}

function renderUnless(element, condition) {
  if (condition) {
    return null;
  } else {
    return element;
  }
}

export {
  renderIf ,
  renderUnless ,
  
}
/* No side effect */
