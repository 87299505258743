// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as ReactUtils$PupilfirstCom from "../../shared/utils/ReactUtils.bs.js";
import * as PupilfirstLmsLogoSvg from "./pupilfirst-lms-logo.svg";
import * as Header__Link$PupilfirstCom from "./types/Header__Link.bs.js";
import * as PupilfirstLmsLogoWhiteSvg from "./pupilfirst-lms-logo-white.svg";

var logo = PupilfirstLmsLogoSvg.default;

var logoWhite = PupilfirstLmsLogoWhiteSvg.default;

function str(prim) {
  return prim;
}

function linkClassesDesktop(bool) {
  return "inline-flex items-center px-2 py-1 text-sm font-semibold rounded transition duration-150 ease-in-out " + (
          bool ? "text-primary-900 bg-gray-200 " : "hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 "
        );
}

function linkClassesMobile(bool) {
  return "mt-1 block pl-3 pr-4 py-2 border-l-4 text-base font-semibold transition duration-150 ease-in-out " + (
          bool ? "border-primary-500 text-primary-900 bg-gray-200 focus:outline-none focus:text-primary-800 focus:bg-primary-100 " : "border-transparent text-gray-800 hover:text-gray-900 hover:bg-gray-100 hover:border-gray-300 focus:outline-none focus:text-gray-900 focus:bg-gray-200 "
        );
}

function Header(Props) {
  var links = Props.links;
  var isLoggedIn = Props.isLoggedIn;
  var allowSignUp = Props.allowSignUp;
  var match = React.useState((function () {
          return true;
        }));
  var toggleMenuHidden = match[1];
  var menuHidden = match[0];
  var url = ReasonReactRouter.useUrl(undefined, undefined);
  var isCurrentPath = function (slug) {
    var match = url.path;
    if (match && !match[1]) {
      return "/" + match[0] === slug;
    } else {
      return false;
    }
  };
  var match$1 = url.path;
  var isHome = match$1 ? false : true;
  return React.createElement("div", {
              className: "max-w-6xl w-full mx-auto px-3 md:px-4 pb-3 md:pb-5"
            }, React.createElement("nav", {
                  className: "flex pt-4 md:pt-5 justify-between items-center"
                }, React.createElement("div", {
                      className: "flex w-full flex-1 justify-between text-center md:text-left "
                    }, React.createElement("a", {
                          className: "flex-shrink-0 flex items-center",
                          href: "/"
                        }, isHome ? React.createElement("img", {
                                className: "h-7 md:h-10",
                                alt: "Pupilfirst Logo",
                                src: logoWhite
                              }) : React.createElement("img", {
                                className: "h-7 md:h-10",
                                alt: "Pupilfirst Logo",
                                src: logo
                              }))), React.createElement("div", {
                      className: "hidden md:flex w-full md:w-auto justify-between md:justify-center items-center overflow-x-auto sm:space-x-3 lg:space-x-5" + (
                        isHome ? " text-white" : " text-gray-800"
                      )
                    }, links.map((function (link) {
                            return React.createElement("a", {
                                        key: Header__Link$PupilfirstCom.slug(link),
                                        className: linkClassesDesktop(isCurrentPath(Header__Link$PupilfirstCom.slug(link))),
                                        href: Header__Link$PupilfirstCom.slug(link)
                                      }, Header__Link$PupilfirstCom.title(link));
                          }))), React.createElement("div", {
                      className: "hidden md:flex flex-1 justify-end items-center"
                    }, isLoggedIn ? React.createElement("div", {
                            className: "inline-flex items-center"
                          }, React.createElement("a", {
                                className: "inline-flex items-center px-2 py-1 text-xs font-semibold rounded transition duration-150 ease-in-out hover:bg-red-500 hover:text-white focus:bg-red-600 focus:text-red-100" + (
                                  isHome ? " text-white" : " text-gray-800"
                                ),
                                href: "/sign_out"
                              }, "Sign Out")) : React.createElement("div", {
                            className: "inline-flex items-center space-x-3"
                          }, React.createElement("a", {
                                className: "inline-flex items-center text-white px-2 py-1 text-sm font-semibold rounded transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900" + (
                                  isHome ? " text-white" : " text-gray-800"
                                ),
                                href: "/sign_in"
                              }, "Sign In"), ReactUtils$PupilfirstCom.renderIf(React.createElement("a", {
                                    className: "btn btn-success",
                                    href: "/sign_up"
                                  }, "Start Free Trial"), allowSignUp))), React.createElement("div", {
                      className: "sm:hidden flex flex-1 justify-end items-center"
                    }, React.createElement("button", {
                          className: "inline-flex items-center justify-center p-2 rounded-md focus:outline-none transition duration-150 ease-in-out" + (
                            isHome ? " text-white focus:text-primary-100" : " text-gray-800 focus:text-gray-900"
                          ),
                          onClick: (function (param) {
                              return Curry._1(toggleMenuHidden, (function (menuHidden) {
                                            return !menuHidden;
                                          }));
                            })
                        }, menuHidden ? React.createElement("svg", {
                                className: "block h-6 w-6",
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24"
                              }, React.createElement("path", {
                                    d: "M4 6h16M4 12h16M4 18h16",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: "2"
                                  })) : React.createElement("svg", {
                                className: "block h-6 w-6",
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24"
                              }, React.createElement("path", {
                                    d: "M6 18L18 6M6 6l12 12",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: "2"
                                  }))))), menuHidden ? null : React.createElement("div", {
                    className: "sm:hidden shadow-lg transition duration-150 ease-in-out" + (
                      isHome ? " bg-white rounded" : ""
                    )
                  }, React.createElement("div", {
                        className: "pt-2 pb-3"
                      }, links.map((function (link) {
                              return React.createElement("a", {
                                          key: Header__Link$PupilfirstCom.slug(link),
                                          className: linkClassesMobile(isCurrentPath(Header__Link$PupilfirstCom.slug(link))),
                                          href: Header__Link$PupilfirstCom.slug(link)
                                        }, Header__Link$PupilfirstCom.title(link));
                            })), isLoggedIn ? React.createElement("div", {
                              className: "flex items-center px-4 text-center mt-2"
                            }, React.createElement("a", {
                                  className: "w-full block rounded pl-3 pr-4 py-2 border border-red-400 text-sm font-semibold transition duration-150 ease-in-out text-red-800 hover:text-gray-900 hover:bg-gray-100 hover:border-red-700 focus:outline-none focus:text-gray-900 focus:bg-gray-200",
                                  href: "/sign_out"
                                }, "Sign Out")) : React.createElement("div", {
                              className: "px-4 mt-3"
                            }, React.createElement("a", {
                                  className: "inline-flex w-full justify-center border items-center text-gray-800 px-2 py-2 text-sm font-semibold rounded transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900",
                                  href: "/sign_in"
                                }, "Sign In"), ReactUtils$PupilfirstCom.renderIf(React.createElement("a", {
                                      className: "btn btn-success w-full mt-3",
                                      href: "/sign_up"
                                    }, "Start Free Trial"), allowSignUp)))));
}

var Link;

var make = Header;

export {
  logo ,
  logoWhite ,
  str ,
  Link ,
  linkClassesDesktop ,
  linkClassesMobile ,
  make ,
  
}
/* logo Not a pure module */
